<template>
  <v-dialog
    v-model="modal"
    width="80%"
    @click:outside="$emit('update:modal', false)"
  >
    <v-card class="modal" :dark="$dark.get()">
      <v-card-text class="modal__text">
        <div class="modal__title">
          <div class="modal__block">
            <span class="text-h6 font-weight-black"
              >Ограничение по отображению</span
            >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon color="primary" v-bind="attrs" v-on="on">
                  <v-icon>mdi-information-variant-circle</v-icon>
                </v-btn>
              </template>
              <span>
                Товар не будет доступен клиенту , если остатки меньше значения
                "Мин. баланс"
              </span>
            </v-tooltip>
          </div>

          <v-btn color="primary" @click="showAddLimit = true"
            >Добавить лимит</v-btn
          >
        </div>

        <p v-if="noLimits">Лимиты не установлены</p>
        <div v-if="showAddLimit">
          <p>
            {{
              editMode
                ? `Изменить лимит для ${newLimit.departmentName}`
                : "Создать лимит"
            }}
          </p>
          <v-form ref="limitForm">
            <v-select
              v-if="!editMode"
              v-model="newLimit.departmentId"
              class="modal__select"
              label="Филиал"
              :items="departments"
              :rules="[$validate.required]"
              required
              item-text="departmentName"
              item-value="departmentId"
            ></v-select>
            <v-select
              v-if="!editMode"
              v-model="newLimit.measureId"
              class="modal__select"
              label="Единица измерения"
              :items="measureTypes"
              :rules="[$validate.required]"
              required
              item-text="name"
              item-value="id"
            ></v-select>
            <v-text-field
              v-model.number="newLimit.minBalance"
              :rules="[$validate.required]"
              label="Минимум товара"
              type="number"
              required
              clearable
            ></v-text-field>
          </v-form>

          <div class="modal__btns">
            <v-btn @click="showAddLimit = false">Отмена</v-btn>
            <v-btn
              color="primary"
              @click="editMode ? changeLimit() : addNewLimit()"
              >Подтвердить</v-btn
            >
          </div>
        </div>
        <div v-if="limits.length > 0" class="custom-card__container">
          <div class="custom-table">
            <div class="head">
              <div
                v-for="item in head"
                :key="item.id"
                class="head__item"
                :class="item.id === 1 && 'name'"
              >
                {{ item.name }}
              </div>
            </div>
            <div
              v-for="item in limits"
              :key="item.providerProductLimitId"
              class="value"
            >
              <div class="value__item_name">
                {{ item.departmentName }}
              </div>
              <div class="value__item">
                {{ item.measureName }}
              </div>
              <div class="value__item">
                {{ item.minBalance }}
              </div>
              <div class="value__item">
                <v-btn icon color="primary" @click="editLimit(item)">
                  <v-icon>mdi-lead-pencil</v-icon>
                </v-btn>
                <v-btn
                  icon
                  color="primary"
                  @click="deleteLimit(item.providerProductLimitId)"
                >
                  <v-icon>mdi-trash-can</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </v-card-text>
      <v-card-actions class="modal__actions">
        <v-btn @click="$emit('update:modal', false)">Закрыть</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import showMessage from "@/Functions/message";
import api from "@/api";

export default {
  name: "ProductLimits",
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
    providerId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      noLimits: false,
      showAddLimit: false,
      limits: [],
      departments: [],
      editableLimit: {},
      editMode: false,
      newLimit: {
        departmentId: 0,
        minBalance: 0,
        measureId: 0,
      },
      measureTypes: [
        { name: "шт.", id: 1 },
        { name: "кг.", id: 2 },
      ],
      head: [
        { id: 1, name: "Филиал" },
        { id: 2, name: "Мера" },
        { id: 3, name: "Мин. баланс" },
        { id: 4, name: "Действия" },
      ],
    };
  },
  watch: {
    modal(val) {
      if (val) {
        this.getProviderLimits();
        this.getProviderDepartments();
      }
    },
    showAddLimit(val) {
      if (!val) {
        this.editMode = false;
        this.newLimit = {};
        this.$refs.limitForm.resetValidation();
      }
    },
  },
  mounted() {},
  methods: {
    async getProviderLimits() {
      let response = await api.Providers.getProviderProductsLimit(
        this.providerId
      );
      if (response.type === "error") {
        this.noLimits = true;
      } else {
        this.noLimits = false;
        this.limits = response;
      }
    },
    async getProviderDepartments() {
      let response = await api.Providers.getProviderDepartments(
        this.providerId
      );
      this.departments = response;
    },
    async addNewLimit() {
      if (this.$refs.limitForm.validate()) {
        this.newLimit.providerId = this.providerId;
        let response = await api.Providers.addProviderProductsLimit(
          this.newLimit
        );
        if (response.type === "error") {
          showMessage(response.data.message);
        } else {
          showMessage("Лимит добавлен", true);
        }
        this.showAddLimit = false;
        this.getProviderLimits();
      }
    },
    async deleteLimit(limitId) {
      let response = await api.Providers.deleteProviderProductsLimit([limitId]);
      if (response.type === "error") {
        showMessage(response.data.message);
      } else {
        showMessage("Лимит удален", true);
      }
      this.getProviderLimits();
    },
    editLimit(limit) {
      this.newLimit = limit;
      this.editMode = true;
      this.showAddLimit = true;
    },
    async changeLimit() {
      let response = await api.Providers.editProviderProductsLimit(
        this.newLimit
      );
      if (response.type === "error") {
        showMessage(response.data.message);
      } else {
        showMessage("Лимит изменен", true);
      }
      this.showAddLimit = false;
    },
  },
};
</script>
<style lang="scss">
.modal {
  padding: 20px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
  }
  &__actions {
    width: 100%;
    justify-content: right;
  }
  &__btns {
    display: flex;
    gap: 20px;
    justify-content: right;
    margin-bottom: 20px;
  }
  &__block {
    display: flex;
    align-items: center;
  }
}
.custom-card__container {
  .custom-table {
    .head {
      display: flex;
      justify-content: space-between;
      &__item {
        text-align: left;
        font-weight: 500;
        width: 20%;
        text-align: center;
      }
    }
    .img {
      width: 5%;
      text-align: center;
    }
    .name {
      width: 50%;
      text-align: left;
    }
    .value {
      display: flex;
      justify-content: space-between;
      &__item {
        width: 20%;
        display: flex;
        align-items: center;
        justify-content: center;
        &_img {
          width: 5%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &_name {
          width: 50%;
          text-align: left;
        }
      }
      &__btn {
        width: 100%;
      }
    }
  }
}
</style>
